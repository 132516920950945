
.header{
    margin: 0px;
    padding:10px 0px;
    border-bottom: 1px solid #c6b2c9;
    display: flex;
    justify-content: center;
}

.headerContainer{
    display: flex;
    justify-content: space-between;
    max-width: 1700px;
    min-width: 320px;
}

.linkContainer{
    display: inline-block;
    background-image: url('../assets/images/button.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    width: 135px;
    height: 65px;
    cursor: pointer;
    text-align: center;
    padding: 0px 30px;
}

.linkContainerSelected{
    background-image: url('../assets/images/selectedButton.svg');
    width: 223px;
    background-size: contain;
    cursor: text;
}

.linkButton{
    color: white;
    display: block;
    padding: 23px 0px;
}

.linkButtonSelected{
    color: #35F9E1;
}

.linkContainer :hover .linkButton{
    color: #35F9E1;
}

.linkButton:hover{
    color: #35F9E1;
}

a{
    text-decoration: none;
}

@media(max-width:480px){
    .headerContainer {
        width: 100%;
        padding: 0px 10px;
    }
    .linkContainer{
        width: 50px;
        margin: 0px 5px;
    }

    .linkContainerSelected{
        width: 120px;
    }
    .linkButton{
        font-size: 14px;
        padding: 23px 0px;
    }
    .logoImage{
        padding: 10px 0px;
        width: 40px;
    }
}

@media (min-width: 481px) and (max-width:767px){
    .headerContainer {
        width: 100%;
        padding: 0px 20px;
    }

    .linkContainer{
        width: 45px;
        margin: 0px 5px;
    }

    .linkContainerSelected{
        width: 110px;
    }
    .linkButton{
        font-size: 8px;
        padding: 27px 0px;
    }
    .logoImage{
        width: 50px;
    }
}

@media (min-width: 768px) and (max-width:1024px) {
    .headerContainer {
        width: 95%;
    }

    .linkContainer{
        width: 100px;
        height: 55px;
        margin: 0px 10px;
    }

    .linkContainerSelected{
        width: 160px;
    }
    .linkButton{
        font-size: 12px;
        padding: 21px 0px;
    }
    .logoImage{
        width: 50px;
    }
}

@media (min-width: 1025px) and (max-width:1200px) {
    .headerContainer {
        width: 85%;
    }

    .linkContainer{
        width: 90px;
        height: 60px;
        margin: 0px 10px;
    }

    .linkContainerSelected{
        width: 180px;
    }
    .linkButton{
        font-size: 14px;
        padding: 21px 0px;
    }
    .logoImage{
        width: 60px;
    }
}

@media (min-width: 1201px) and (max-width: 1680px) {
    .headerContainer {
        width: 80%;
    }
    .linkButton{
        font-size: 14px;
        padding: 24px 0px;
    }
    .logoImage{
        width: 60px;
    }
}

@media (min-width: 1681px) {
    .headerContainer {
        width: 75%;
    }
    .linkButton{
        font-size: 16px;
    }
    .logoImage{
        width: 65px;
    }
}

@media (min-width: 2000px) {
    .headerContainer {
        width: 70%;
    }
}
