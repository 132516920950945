body{
    font-family: 'Futura';
}
.backgroundImage{
    width: 100%;
    position:absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: -1;
}
.landingPage {
    background-image: url('../assets/images/landing-page-background.jpg');
    min-height: 130%;
    background-size: cover; /* Ensures the image covers the wrapper fully */
    background-repeat: no-repeat;
    // background-position: top center;
    z-index: -5;
}
.firstSectionContainer{
    display: flex;
    justify-content: center;
}
.first-section{
    padding: 60px 0px;
    display: block;
    max-width: 1700px;
    min-width: 320px;
    background-image: url('../assets/images/landing-first-background.svg');
    background-repeat: no-repeat;
    background-position: right top 55px ;
    .container{
        p{
            font-style: normal;
            font-weight: 800;
            font-size: 72px;
            line-height: 86px;
            color: #00FFFF;
            margin-bottom: 0px;
        }
        .button-container{
            padding: 25px 0px;
            display: flex;
            gap: 15px;
            div{
                display: inline-block;
                background-image: url('../assets/images/first-section-button.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                width: 200px;
                height: 51px;
                text-align: center;
                a{
                    padding: 16.5px 0px;
                    display: inline-block;
                    /* Liquidity Amplification */
                    font-style: normal;
                    font-weight: semi-bold;
                    font-size: 11px;
                    line-height: 17px;
                    color: #00FFFF;
                }
                a:hover{
                    color: #35F9E1;
                }
            }
        }
        .read-more{
            div{
                display: inline-block;
                background-image: url('../assets/images/read-more.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                width: 200px;
                height: 73px;
                cursor: pointer;
                text-align: center;
                a{
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 25px;
                    text-align: center;
                    color: black;
                    padding: 23px 0px 0px 0px;
                    display: inline-block;
                }
                a:hover{
                    color: #242324;
                }
            }
        }
    }
}

.secondSectionContainer{
    display: flex;
    justify-content: center;
}
.second-section{
    padding: 60px 0px;
    display: block;
    .section-description {
        -webkit-text-fill-color: #0000;
        background: linear-gradient(277.67deg, #E84AF8 13.35%, #00FFFF 79.69%);
        background-clip: text;
        font-style: normal;
        font-weight: 200;
        text-align: center;
        line-height: 60px !important;
        font-size: 54px !important;
    }
    .titleContainer{
        text-align: center;
        p{
            font-style: normal;
            font-weight: 200;
            font-size: 60px;
            line-height: 80px;
            text-align: center;
            // text-transform: uppercase;
            background: linear-gradient(277.67deg, #E84AF8 13.35%, #00FFFF 79.69%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
        }
    }
}

.cardsContainer{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    gap:5px;
    .cardContianer{
        height: 560px;
        box-sizing: border-box;
        background: rgba(29, 34, 40, 0.5);
        backdrop-filter: blur(2px);
        border-radius: 60px
    }
    .yellowCard{
        border: 2px solid #FFB801;
        box-shadow: 0px 0px 10px #FFB801, inset 0px 0px 8.4px #F45DD3;
        .top{
            height: 215px;
            bottom: 100px !important
        }
        .contentContainer{
            .title{
                font-style: normal;
                font-weight: 800;
                font-size: 36px;
                line-height: 45px;
                text-align: center;
                background: linear-gradient(93.52deg, #FFFE78 11.55%, #B98501 97.1%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                margin: 0px;
                padding:0px;
            }
        }
    }
    .pinkCard{
        border: 2px solid #E478EE;
        box-shadow: 0px 0px 10px #E478EE, inset 0px 0px 8.4px #FFB801;
        .contentContainer{
            padding: 125px 40px;
            .title{
                font-style: normal;
                font-weight: 800;
                font-size: 36px;
                line-height: 45px;
                text-align: center;
                background: linear-gradient(95.5deg, #E84AF8 30.51%, #892C92 85.73%);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                margin: 0px;
                padding:0px;
            }
        }
    }
    .greenCard{
        border: 2px solid #71C749;
        box-shadow: 0px 0px 10px #B0FF8C, inset 0px 0px 8.4px #B0FF8C;
        .contentContainer{
            .title{
                font-style: normal;
                font-weight: 800;
                font-size: 36px;
                line-height: 45px;
                text-align: center;
                background: linear-gradient(96.33deg, #8AF8DD 18.78%, #1BA583 79.92%), #FFFFFF;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                background-clip: text;
                text-fill-color: transparent;
                margin: 0px;
                padding:0px;
            }
        }
    }
    .imageContainer{
        display: flex;
        justify-content: center;
        height: 130px;
        .top{
            position: relative;
            bottom: 70px;
        }
    }
    .imageContainerBottom{
        display: flex;
        justify-content: center;
        .bottom{
            position: absolute;
            bottom: -30px;
        }
    }
    .contentContainer{
        text-align: center;
        padding: 0px 40px;
        .description{
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 26px;
            text-align: center;
            color: #FFFFFF;
            opacity: 0.8;
        }
    }
    .lineContainer{
        display: flex;
        justify-content: center;
        .yellowLine{
            position: absolute;
            bottom: -8px;
            width: 224px;
            height: 5px;
            background: #FFB801;
            filter: blur(2px);
            border-radius: 30px;
        }
        .greenLine{
            position: absolute;
            bottom: -8px;
            width: 207px;
            height: 5px;
            background: #B0FF8C;
            filter: blur(2px);
            border-radius: 30px;
        }
        .pinkLine{
            position: absolute;
            top: -8px;
            width: 196px;
            height: 5px;
            background: #F45DD3;
            filter: blur(2px);
            border-radius: 30px;
        }
    }
}

.sectionContainer{
    max-width: 1700px;
    min-width: 320px;
}

.thirdSectionContainer{
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
}

.thirdSection{
    padding: 60px 0px;
    display: block;
    .titleContainer{
        text-align: center;
        p{
            font-style: normal;
            font-weight: 800;
            font-size: 60px;
            line-height: 80px;
            text-align: center;
            text-transform: uppercase;
            background: linear-gradient(277.67deg, #E84AF8 13.35%, #00FFFF 79.69%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
    }
    .cardsContainer{
        padding: 55px 0px 5px 0px;
        .img{
            width: 100%;
        }
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        .leftCardContainer{
            background: rgba(29, 34, 40, 0.5);
            border: 2px solid #00FFFF;
            box-shadow: 0px 0px 10px #00FFFF, inset 0px 0px 8.4px #00FFFF;
            border-radius: 60px;
            background-image: url('../assets/images/miningBackground.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            .container{
                width: 100%;
                height: 100%;
                border-radius: 60px;
                backdrop-filter: blur(2px);
            }
            .yellowBlur{
                width: 583.75px;
                height: 309.23px;
                background: rgba(128, 222, 85, 0.5);
                filter: blur(200px);
                position: absolute;
                top:10%;
                right: 0%;
            }
            .contentsContainer{
                padding: 0px 70px;
                .mintingTitle{
                    font-style: normal;
                    font-weight: 700;
                    font-size: 40px;
                    line-height: 53px;
                    background: linear-gradient(277.67deg, #E84AF8 13.35%, #00FFFF 79.69%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                    margin: 0px;
                    display: inline-block;
                }
                .mintingDescription{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 30px;
                    letter-spacing: 0.02em;
                    font-feature-settings: 'kern' off;
                    color: #FFFFFF;
                    opacity: 0.8;
                }
            }
        }
        .rightCardContainer{
            height: 860px;
            grid-template-rows: repeat(12, 1fr);
            gap: 5px;
            z-index: -1;
            display: grid;
            .topCardContainer{
                width: 100%;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 5px;
                height: 350px;

                .leftCard{
                    box-sizing: border-box;
                    background: rgba(29, 34, 40, 0.5);
                    border: 2px solid #F45DD3;
                    box-shadow: 0px 0px 10px #00FFFF, inset 0px 0px 8.4px #00FFFF;
                    backdrop-filter: blur(2px);
                    border-radius: 60px;
                    .img{
                        height: 330px;
                        border-radius: 60px;
                    }
                }
                .rightCard{
                    background: rgba(29, 34, 40, 0.5);
                    border: 2px solid #E84AF8;
                    box-shadow: 0px 0px 10px #00FFFF, inset 0px 0px 8.4px #00FFFF;
                    backdrop-filter: blur(2px);
                    border-radius: 60px;
                    .img{
                        height: 340px;
                        border-radius: 60px;
                    }
                }
            }
            .bottomCardContainer{
                width: 100%;
                height: 505px;
                background: rgba(29, 34, 40, 0.5);
                border: 2px solid #FFB801;
                box-shadow: 0px 0px 10px #FFB801, inset 0px 0px 10px #FFFE78;
                backdrop-filter: blur(2px);
                border-radius: 60px;
                .img{
                    border-radius: 60px;
                    height: 500px;
                }
            }
        }
    }
    .miningImage{
        position: absolute;
        top: -80px;
        right: -80px;
    }
    .vectorsContainer{
        .yellowContainer{
            position: absolute;
            top: 10%;
            left: 20%;
            background: #DBFF00;
            .victor{
                position: absolute;
                right: -10px;
                bottom: -20px;
            }
        }
        .blueContainer{
            position: absolute;
            top: 20%;
            left: 12%;
            background: #60F0F9;
            .victor{
                position: absolute;
                right: -25px;
                bottom: 0px;
            }
        }
        .pinkContainer{
            position: absolute;
            top: 30%;
            left: 15%;
            background: #F45DD3;
            .victor{
                position: absolute;
                right: -25px;
                bottom: 0px;
            }
        }
        .orangeContainer{
            position: absolute;
            top: 40%;
            left: 20%;
            background: #FFB801;
            .victor{
                position: absolute;
                right: -25px;
                bottom: 10px;
            }
        }
        .vectorContainer{

            width: 68px;
            height: 26px;
            border-radius: 32px;
            text-align: center;
            p{
                padding: 5px 0px;
                margin: 0px;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 16px;
                color: #000000;
            }
        }
    }
    .topContainer{
        height: 500px;
    }
    .secondCardsContainer{
        height: 700px;
        width: 100%;
        border: 2px solid gray;
    }
    .arrowsContainer{
        padding: 10px 70px;
        .leftArrow{
            width: 53px;
            height: 53px;
            background-image: url('../assets/images/left-arrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
            display: inline-block;
        }
        .rightArrow{
            width: 53px;
            height: 53px;
            margin-left: 25px;
            background-image: url('../assets/images/right-arrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            cursor: pointer;
            display: inline-block;
        }
    }
    .summary{
        background: rgba(29, 34, 40, 0.5);
        border: 2px solid #DBFF00;
        box-shadow: 0px 0px 10px #00FFFF, inset 0px 0px 8.4px #00FFFF;
        backdrop-filter: blur(2px);
        border-radius: 60px;
        width: 100%;
        background-image: url('../assets/images/summary-background.svg');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        .summaryContainer{
            padding: 90px 70px;
            border-radius: 60px;
            backdrop-filter: blur(2px);
        }
        .summaryCardsContainer{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;
            gap:25px;
            .cardContainer{
                height: 350px;
                background-repeat: no-repeat;
                .cardTitle{
                    font-style: normal;
                    font-weight:700;
                    font-size: 28px;
                    line-height: 50px;
                    color: #00FFFF;
                    margin: 0px;
                }
                .cardDescription{
                    font-style: normal;
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 25px;
                    color: #00FFFF;
                    opacity: 0.8;
                    margin: 0px;
                    width: 380px;
                }
            }
            .summaryGridContainer{
                display: grid;
                grid-template-columns: repeat(3, 1fr);
            }
            .summaryGridContainerParent{
                height: 100%;
                display: grid;
                grid-template-rows: repeat(3, 1fr);
            }
            .recivingCardContainer{
                background-image: url('../assets/images/receivingSummaryImage.svg');
                background-size: contain;
                .cardTitle{
                    padding-left: 60px;;
                }
                .cardDescription{
                    padding-left: 60px;
                }
            }
            .mintingCardContainer{
                background-image: url('../assets/images/mintingSummaryImage.svg');
                background-size: contain;
                .cardTitle{
                    padding-left: 30px;
                }
                .cardDescription{
                    padding-left: 60px;
                }
            }
            .burningCardContainer{
                background-image: url('../assets/images/burningSummaryImage.svg');
                background-size: contain;
                .cardTitle{
                    margin: -42px 0px;
                    padding-left: 60px;
                }
                .cardDescription{
                    padding-left: 60px;
                    margin: -42px 0px;
                }
            }
            .stakingCardContainer{
                background-image: url('../assets/images/stakingSummaryImage.svg');
                background-size: contain;
                .stakingGridContainer{
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                }
                .cardTitle{
                    margin: -42px 0px;
                    padding-left: 30px;
                }
                .cardDescription{
                    padding-left: 60px;
                    margin: -42px 0px;
                }
            }
        }
        .title{
            font-style: normal;
            font-weight: 600;
            font-size: 40px;
            line-height: 53px;
            background: linear-gradient(277.67deg, #E84AF8 13.35%, #00FFFF 79.69%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
            display: inline-block;
        }
        .arrowsContainer{
            padding: 0px;
        }
    }
}

.qaSection{
    padding: 100px 0px;
    display: flex;
    justify-content: center;
    .titleContainer{
        text-align: center;
    }
    .title{
        display: inline;
        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 80px;
        text-transform: uppercase;
        background: linear-gradient(277.67deg, #E84AF8 13.35%, #00FFFF 79.69%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    .qasContainer{
        width: 100%;
        padding: 50px 0px;
        margin: 50px 0px;
        background: rgba(29, 34, 40, 0.5);
        border: 2px solid #E84AF8;
        box-shadow: 0px 0px 10px #E84AF8, inset 0px 0px 8.4px #00FFFF;
        backdrop-filter: blur(2px);
        /* Note: backdrop-filter has minimal browser support */
        border-radius: 60px;
        background-image: url('../assets/images/summary-background.svg');
        background-repeat: repeat;
        background-position: center;
        background-size: cover;
        .questionContainer{
            display: flex;
            justify-content: space-between;
            padding: 30px 0px;
        }
        .openArrow{
            background-image: url('../assets/images/openArrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 15px;
            height: 28px;
            cursor: pointer;
            margin:0px;
        }
        .closeArrow{
            background-image: url('../assets/images/closeArrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 15px;
            height: 28px;
            cursor: pointer;
            margin: 0px;
        }
        .flexContainer{
            display: flex;
            justify-content: center;
        }
    }
    .qaContainer{
        width: 90%;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0.9;
        border-radius: 30px;
        margin: 10px 0px 0px 0px;
        padding: 0px 40px 0px 40px;
        .question{
            margin: 0px;
            padding: 0px;
            font-style: normal;
            font-weight: 600;
            font-size: 26px;
            line-height: 42px;
            /* identical to box height */
            cursor: pointer;

            /* Linear */
            background: linear-gradient(277.67deg, #E84AF8 13.35%, #00FFFF 79.69%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
        .answar{
            padding:0px 0px 50px 0px;
            margin:-25px 25px;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 46px;
            /* or 177% */
            color: #00FFFF;

            opacity: 0.6;
        }
        a{
            color:#3ab9f0;
        }
    }
}

.fourthSection{
    padding: 60px 0px;

    .wlitecoin-cover{
        height: 650px;
        width: 100%;
        background-image: url('../assets/images/wlitecoin-cover.svg');
        background-position: center;
        background-repeat: no-repeat;

        .wlitecoin-logo{
            display: flex;
            height: 100%;
            justify-content: center;
            align-items: center;

            img{
                width: 350px;
                height: 350px;
            }
        }
        .blur{
            background: #c4c4c400;
            backdrop-filter: blur(2px);
            width: 100%;
            height: 100%;
        }
    }
}

.joinCommunity{
    text-align: center;

    .title{
        display: inline;
        font-style: normal;
        font-weight: 700;
        font-size: 60px;
        line-height: 80px;
        text-transform: uppercase;
        background: linear-gradient(277.67deg, #E84AF8 13.35%, #00FFFF 79.69%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    .buttonsContainer{
        display: flex;
        justify-content: center;
        padding: 50px 0px;
        gap: 40px;
    }
    .button{
        div{
            display: inline-block;
            background-image: url('../assets/images/read-more.svg');
            background-repeat: no-repeat;
            background-position: center;
            width: 200px;
            height: 73px;
            cursor: pointer;
            text-align: center;
            a{
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 25px;
                text-align: center;
                color: black;
                padding: 25px 0px 0px 0px;
                display: inline-block;
                text-transform: uppercase;
            }
            a:hover{
                color: #242324;
            }
        }
    }
}

.partnersCover{
    img{
        padding: 100px 0px 0px 0px;
        position: absolute;
        width: 100%;
        max-height: 1000px;
    }
}

.partnersContainer{
    padding: 250px 0px 0px 0px;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 1;
    .titleContainer{
        text-align: center;
        .title{
            display: inline;
            font-style: normal;
            font-weight: 700;
            font-size: 60px;
            line-height: 80px;
            text-transform: uppercase;
            background: linear-gradient(277.67deg, #E84AF8 13.35%, #00FFFF 79.69%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
    }
    .cardsContainer{
        padding: 70px 0px;
        display: flex;
        justify-content: center;
        justify-content: space-around;

        .cardContainer{
            width: 250px;
            height: 273px;
            border-radius: 60px;
            position: relative;
            .card{
                box-sizing: border-box;
                width: 250px;
                height: 226px;
                backdrop-filter: blur(2px);
                border-radius: 60px;
                position: absolute;
                bottom: 0px;
                p{
                    padding: 0px;
                    margin: 5px 0px ;
                    font-weight: 850;
                    font-size: 30px;
                    line-height: 40px;
                    color: #000000;
                }
                a{
                    text-decoration: none;
                    color: #000000;
                }
                a:hover{
                    text-decoration: underline;
                }
                .img{
                    padding: 50px 0px 0px 0px;
                }
            }
        }

        .fireblocksCardContainer{
            background: #00FFFF;
            box-shadow: 0px 20px 30px -5px rgba(96, 240, 249, 0.5);
            .fireblocksCard{
                background: rgba(255, 255, 255, 0.5);
                border: 1px solid #FFFFFF;
                box-shadow: 0px 0px 4px #FFFFFF, inset 0px 0px 10px #FFFFFF;
            }
        }
        .coinbaseCardContainer{
            background: #E84AF8;
            box-shadow: 0px 20px 30px 7px rgba(225, 144, 234, 0.5);
            .coinbaseCard{
                background: rgba(255, 255, 255, 0.5);
                border: 1px solid #FFFFFF;
                box-shadow: 0px 0px 4px #DFA7E5, inset 0px 0px 10px #FFFFFF;
            }
        }
        .coinutCardContainer{
            background: #DBFF00;
            box-shadow: 0px 20px 30px 7px #555555;
            .coinutCard{
                background: rgba(255, 255, 255, 0.5);
                border: 1px solid #FFFFFF;
                box-shadow: 0px 0px 4px #FFFFFF, inset 0px 0px 5px #FFFFFF;

            }
        }
    }
}

.footerSection{
    display: flex;
    justify-content: center;
    margin: 250px 0px 0px 0px;
    position: relative;
    background-color: red;
    width: 100%;
    height: 225px;
    background-color: #23252e;
    .footerContainer{
        height: 150px;
        background: #00FFFF;
        border-radius: 30px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        margin-top: -75px;
        a{
            cursor: pointer;
            font-style: normal;
            font-weight: 600;
            font-size: 18px;
            line-height: 21px;
            color: #000000;
        }
        a:hover{
            color: #333333;
        }

    }
    .copyrightContainer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 75px 0px 0px 0px;

        .text{
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;

            color: #FFFFFF;

            opacity: 0.5;
        }
        .iconsContainer{
            display: flex;
            gap: 30px;
            div {
                border-color: #fff;
                border-style: solid;
                border-radius: 50%;
                border-width: thin;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                svg{
                    cursor: pointer;
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }
}

@media (max-width:480px){
    .first-section{
        width: 100%;
        background-position: right top 88px ;
        background-image: url('../assets/images/landing-first-background.svg');
        background-size:300px;
        padding: 30px 10px;
    }
    .sectionContainer{
        width: 100%;
        padding:0px 10px !important;
    }
    .first-section{
        .container{
            p{
                font-size: 26px;
                line-height: 40px;
                display: inline-block;
            }
            .button-container{
                display: inline-block;
                float: right;
                margin-top: 40px;
                div{
                    width: 130px;
                    height: 30px;
                    margin-bottom: 10px;
                    display: flex;
                    justify-content: center;
                    a{
                        font-size: 7px;
                        padding: 7px 0px;
                        line-height: 18px;
                        font-weight: bold;
                    }
                }
            }
            .read-more{
                div{
                    padding: 0px;
                    width: 80px;
                    height: 35px;
                    margin-top: 20px;
                    a{
                        font-size: 12px;
                        padding: 5px 0px;
                    }
                }
            }
        }
    }
    .first-section .container .button-container{
        gap: 1px !important;
        padding: 0px 0px;
    }
    .section-title{
        font-size: 20px !important;
        line-height: 25px !important;
        margin: 40px 0px;
    }
    .cardsContainer{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
        gap:70px;
        justify-content: center;
        justify-items: center;
        .cardContianer{
            max-width: 350px;
            width: 90%;
            height: auto !important;
            padding: 0px;
            margin: 0px;
            .imageContainer{
                height: 100px;
            }
        }
    }
    .greenCard{
        .imageContainer{
            height: 90px;
            margin-bottom: -50px;
        }
    }
    .contentContainer{
        padding: 0px 12px 12px 40px !important;
        padding-left: 12px !important;
        padding-right: 12px !important;
        .title{
            font-size: 18px !important;
            line-height: 25px !important;
        }
        .description{
            font-size: 11px !important;
            line-height: 22px !important;
        }
    }
    .thirdSection{
        .section-title{
            margin: 40px 0px 0px 0px;
        }
    }
    .pinkCard{
        .contentContainer{
            padding: 110px 40px 40px 40px!important;
        }
    }
    .imageContainerBottom{
        display: flex;
        justify-content: center;
        .bottom{
            position: absolute;
            top: -60px;
        }
    }
    .pinkLine{
        top:auto !important;
        bottom: -8px;
    }
    .thirdSection{
        .cardsContainer{
            gap: 5px;
            padding: 55px 0px 5px 0px;
            grid-template-columns: repeat(1, 1fr);
            .leftCardContainer{
                height: auto;
                border-radius: 20px;
                .contentsContainer{
                    padding: 0px 5px;
                    .mintingTitle{
                        font-size: 18px;
                        line-height: 23px;
                    }
                    .mintingDescription{
                        font-size: 11px;
                        line-height: 13px;
                        height: 78px;
                    }
                }
            }
            .rightCardContainer{
                height: auto;
                grid-template-rows:none;
                .topCardContainer{
                    height: auto;
                    .leftCard{
                        box-sizing: border-box;
                        height: auto;
                        border-radius: 20px;
                        .img{
                            border-radius: 20px;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .rightCard{
                        box-sizing: border-box;
                        height: auto;
                        border-radius: 20px;

                        .img{
                            border-radius: 20px;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .bottomCardContainer{
                    height: auto;
                    border-radius: 20px;
                    .img{
                        border-radius: 20px;
                        height: 100%
                    }
                }
            }
        }
        .miningImage{
            position: absolute;
            top: -50px;
            right: -10px;
            width: 230px;
            height: 230px;
        }
        .topContainer{
            height: 110px;
        }
        .vectorsContainer{
            .yellowContainer{
                top: -15%;
                left: 20%;
                width: 90px;
                height: 20px;
                p{
                    font-size: 15px;
                    padding: 1px 0px;
                }
                .victor{
                    right: -10px;
                    bottom: -20px;
                }
            }
            .blueContainer{
                top: 0%;
                left: 7%;
                width: 90px;
                height: 20px;
                p{
                    font-size: 15px;
                    padding: 0px 0px;
                }
                .victor{
                    right: -25px;
                    bottom: 0px;
                }
            }
            .pinkContainer{
                top: 15%;
                left: 10%;
                width: 90px;
                height: 20px;
                p{
                    font-size: 15px;
                    padding: 0px 0px;
                }
                .victor{
                    right: -25px;
                    bottom: 0px;
                }
            }
            .orangeContainer{
                top: 30%;
                left: 15%;
                width: 90px;
                height: 20px;
                p{
                    font-size: 15px;
                    padding: 0px 0px;
                }
                .victor{
                    right: -25px;
                    bottom: 10px;
                }
            }

        }
    }
    .arrowsContainer{
        padding: 0px 5px !important;
        .leftArrow{
            width: 30px !important;
            height: 30px !important;
            background-image: url('../assets/images/left-arrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            cursor: pointer;
            display: inline-block;
        }
        .rightArrow{
            width: 30px !important;
            height: 30px !important;
            margin-left: 10px !important;
            background-image: url('../assets/images/right-arrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            cursor: pointer;
            display: inline-block;
        }
    }
    .yellowBlur{
        z-index: -1;
    }
    .recivingCardContainer{
        .cardTitle{
            padding-left: 15px !important;
            margin-top: -20px !important;
        }
        .cardDescription{
            padding-left: 15px !important;
            margin-top: -5px !important;
        }
    }
    .mintingCardContainer{
        .cardTitle{
            padding-left: 15px !important;
            margin-top: -20px !important;
        }
        .cardDescription{
            padding-left: 18px !important;
            margin-top: -8px !important;
        }
    }
    .burningCardContainer{
        .cardTitle{
            margin-top: -30px !important;
            padding-left: 15px !important;
        }
        .cardDescription{
            padding-left: 15px !important;
            margin-top: -18px !important;
        }
    }
    .stakingCardContainer{
        .cardTitle{
            margin: -30px 0px !important;
            padding-left: 15px !important;
        }
        .cardDescription{
            padding-left:18px !important;
            margin: -18px 0px !important;
        }
    }
    .title{
        margin: 0px !important;
    }
    .summary{
        border-radius: 20px !important;
        .title{
            font-size: 18px !important;
            margin: 0px !important;
        }
    }
    .summaryContainer{
        padding: 20px 10px !important;
    }
    .summaryCardsContainer{
        gap:5px !important;
        .cardContainer{
            height: 100px !important;
            .cardTitle{
                font-size: 9px !important;
            }
            .cardDescription{
                font-size: 9px !important;
                line-height: 9px !important;
                max-width: 140px !important;
            }
        }
    }
    .qaSection{
        padding: 50px 0px;
        .qasContainer{
            width: 100%;
            border-radius: 15px;
            padding: 20px 10px;
            margin: 20px 0px;
            box-sizing: border-box;
            .openArrow{
                width: 11px;
                height: 24px;
            }
            .closeArrow{
                width: 11px;
                height: 24px;
            }
        }
        .qaContainer{
            width: 95%;
            border-radius: 10px;
            padding: 0px 20px 0px 20px;
            .questionContainer{
                padding: 10px 0px;
            }
            .question{
                font-size: 13px;
                line-height: 20px;
            }
            .answar{
                margin: 0px 10px -15px 10px;
                padding: 0px 0px 30px 0px;
                font-weight: 600;
                font-size: 11px;
                line-height: 13px;
            }
        }
    }
    .fourthSection{
        padding: 0px 0px;
        .wlitecoin-cover{
            height: 140px;
            width: 100%;
            background-size: contain;

            .wlitecoin-logo{
                height: 100%;
                img{
                    width: 110px;
                    height: 110px;
                }
            }
        }
    }
    .joinCommunity{
        .buttonsContainer{
            padding: 15px 0px;
            gap: 5px;
        }
        .button{
            div{
                background-size: contain;
                width: 130px;
                height: 45px;
                a{
                    font-size: 8px;
                    line-height: 25px;
                    padding: 12px 0px 0px 0px;
                }
            }
        }
    }
    .partnersContainer{
        padding: 50px 0px 0px 0px;
        .cardsContainer{
            padding: 50px 0px;
            gap: 0px;
            .cardContainer{
                width: 80px;
                height: 85px;
                border-radius: 20px;
                .card{
                    width: 80px;
                    height: 75px;
                    border-radius: 20px;
                    p{
                        padding: 0px;
                        margin: 0px ;
                        font-weight: 850;
                        font-size: 12px;
                        line-height: 18px;
                    }
                    .img{
                        padding: 15px 0px 0px 0px;
                        width: 20px;
                    }
                }
            }
        }
    }
    .footerSection{
        margin: 150px 0px 0px 0px;
        width: 100%;
        height: 100px;
        .footerContainer{
            height: 70px;
            border-radius: 20px;
            margin-top: -40px;
            a{
                font-size: 10px;
                line-height: 21px;
            }
            img{
                width: 20px;
                height: 20px;
            }
        }
        .copyrightContainer{
            padding: 20px 0px 0px 0px;
            .text{
                font-size: 10px;
                line-height: 21px;
            }
            .iconsContainer{
                gap: 5px;
                div {
                    width: 25px;
                    height: 25px;
                    svg{
                        cursor: pointer;
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }
}

@media (min-width: 481px) and (max-width:767px){
    .first-section{
        width: 100%;
        background-position: right top 102px ;
        background-image: url('../assets/images/landing-first-background.svg');
        background-size:440px;
        padding: 30px 20px;
    }
    .sectionContainer{
        width: 100%;
        padding:0px 20px !important;
    }
    .first-section{
        .container{
            p{
                font-size: 36px;
                line-height: 50px;
            }
            .button-container{
                div{
                    width: 100px;
                    height: 51px;
                    gap: 10px;
                    a{
                        font-size: 6px;
                        line-height: 18px;
                    }
                }
            }
            .read-more{
                div{
                    padding: 0px;
                    width: 68px;
                    height: 35px;
                    a{
                        font-size: 8px;
                        padding: 6px 0px;
                    }
                }
            }
        }
    }
    .first-section .container .button-container{
        gap: 5px !important;
        padding: 0px 0px;
    }
    .section-title{
        font-size: 30px !important;
        line-height: 40px !important;
        padding-top: 100px;
    }
    .cardsContainer{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
        gap:70px;
        justify-content: center;
        justify-items: center;
        .cardContianer{
            max-width: 400px;
            width: 90%;
            height: auto !important;
            padding: 0px;
            margin: 0px;
            .imageContainer{
                height: 110px;
            }
        }
    }
    .greenCard{
        .imageContainer{
            height: 100px;
            margin-bottom: -40px;
        }
    }
    .contentContainer{
        padding: 0px 20px 20px 40px !important;
        padding-left: 25px !important;
        padding-right: 25px !important;
        .title{
            font-size: 26px !important;
            line-height: 30px !important;
        }
        .description{
            font-size: 14px !important;
        }
    }
    .pinkCard{
        .contentContainer{
            padding: 110px 40px 40px 40px!important;
        }
    }
    .imageContainerBottom{
        display: flex;
        justify-content: center;
        .bottom{
            position: absolute;
            top: -60px;
        }
    }
    .pinkLine{
        top:auto !important;
        bottom: -8px;
    }
    .thirdSection{
        .cardsContainer{
            gap: 5px;
            padding: 55px 0px 5px 0px;
            .leftCardContainer{
                height: auto;
                border-radius: 30px;
                .contentsContainer{
                    padding: 0px 10px;
                    .mintingTitle{
                        font-size: 24px;
                        line-height: 28px;
                    }
                    .mintingDescription{
                        font-size: 10px;
                        line-height: 18px;
                    }
                }
            }
            .rightCardContainer{
                height: auto;
                grid-template-rows:none;
                gap: 5px;
                .topCardContainer{
                    height: auto;
                    .leftCard{
                        border-radius: 30px;
                        height: auto;
                        .img{
                            width: 100%;
                            height: 100%;
                            border-radius: 30px;
                        }
                    }
                    .rightCard{
                        height: auto;
                        border-radius: 30px;
                        .img{
                            border-radius: 30px;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .bottomCardContainer{
                    height: auto;
                    border-radius: 30px;
                    .img{
                        border-radius: 30px;
                        height: 100%
                    }
                }
            }
        }
        .miningImage{
            position: absolute;
            top: -80px;
            right: -80px;
            width: 320px;
            height: 320px;
        }
        .topContainer{
            height: 200px;
        }
        .vectorsContainer{
            .yellowContainer{
                top: 4%;
                left: 15%;
                p{
                    font-size: 10px;
                }
                .victor{
                    right: -10px;
                    bottom: -20px;
                }
            }
            .blueContainer{
                top: 13%;
                left: 7%;
                p{
                    font-size: 10px;
                }
                .victor{
                    right: -25px;
                    bottom: 0px;
                }
            }
            .pinkContainer{
                top: 22%;
                left: 10%;
                p{
                    font-size: 10px;
                }
                .victor{
                    right: -25px;
                    bottom: 0px;
                }
            }
            .orangeContainer{
                top: 30%;
                left: 15%;
                p{
                    font-size: 10px;
                }
                .victor{
                    right: -25px;
                    bottom: 10px;
                }
            }

        }
    }
    .arrowsContainer{
        padding: 0px 15px !important;
        .leftArrow{
            width: 33px !important;
            height: 33px !important;
            background-image: url('../assets/images/left-arrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            cursor: pointer;
            display: inline-block;
        }
        .rightArrow{
            width: 33px !important;
            height: 33px !important;
            margin-left: 10px !important;
            background-image: url('../assets/images/right-arrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            cursor: pointer;
            display: inline-block;
        }
    }
    .recivingCardContainer{
        .cardTitle{
            padding-left: 30px !important;
            margin-top: -15px !important;
        }
        .cardDescription{
            padding-left: 30px !important;
        }
    }
    .mintingCardContainer{
        .cardTitle{
            padding-left: 30px !important;
            margin: -15px 0px !important;
        }
        .cardDescription{
            padding-left: 30px !important;
            margin: -10px 0px !important;
        }
    }
    .burningCardContainer{
        .cardTitle{
            margin-top: -35px !important;
            padding-left: 40px !important;
        }
        .cardDescription{
            padding-left: 30px !important;
            margin-top: -30px !important;
        }
    }
    .stakingCardContainer{
        .cardTitle{
            margin: -35px 0px !important;
            padding-left: 30px !important;
        }
        .cardDescription{
            padding-left:30px !important;
            margin: -30px 0px !important;
        }
    }
    .title{
        margin: 0px !important;
    }
    .summary{
        border-radius: 20px !important;
        .title{
            font-size: 18px !important;
            margin: 0px !important;
        }
    }
    .summaryContainer{
        padding: 20px 10px !important;
    }
    .summaryCardsContainer{
        gap:5px !important;
        .cardContainer{
            height: 150px !important;
            .cardTitle{
                font-size: 13px !important;
            }
            .cardDescription{
                font-size: 9px !important;
                line-height: 11px !important;
                max-width: 170px !important;
            }
        }
    }
    .qaSection{
        padding: 100px 0px;
        .qasContainer{
            padding: 20px 10px;
            margin: 20px 0px;
            box-sizing: border-box;
            width: 100%;
            border-radius: 20px;
            .openArrow{
                width: 11px;
                height: 24px;
            }
            .closeArrow{
                width: 11px;
                height: 24px;
            }
        }
        .qaContainer{
            width: 95%;
            border-radius: 15px;
            .questionContainer{
                padding: 13px 0px;
            }
            .question{
                font-size: 12px;
                line-height: 20px;
            }
            .answar{
                font-weight: 600;
                font-size: 9px;
                line-height: 16px;
                margin: -15px 10px;
                padding: 0px 0px 30px 0px;
            }
        }
    }
    .fourthSection{
        padding: 0px 0px;
        .wlitecoin-cover{
            height: 400px;
            width: 100%;
            background-size: contain;

            .wlitecoin-logo{
                height: 100%;
                img{
                    width: 150px;
                    height: 150px;
                }
            }
        }
    }
    .joinCommunity{
        .buttonsContainer{
            padding: 15px 0px;
            gap: 10px;
        }
        .button{
            div{
                background-size: contain;
                width: 140px;
                height: 50px;
                a{
                    font-size: 9px;
                    line-height: 25px;
                    padding: 12.5px 0px 0px 0px;
                }
            }
        }
    }
    .partnersContainer{
        padding: 150px 0px 0px 0px;
        .cardsContainer{
            padding: 60px 0px;
            gap: 0px;
            .cardContainer{
                width: 120px;
                height: 130px;
                border-radius: 30px;
                .card{
                    width: 120px;
                    height: 110px;
                    border-radius: 30px;
                    p{
                        padding: 0px;
                        margin: 0px ;
                        font-weight: 850;
                        font-size: 16px;
                    }
                    .img{
                        padding: 20px 0px 0px 0px;
                        width: 25px;
                    }
                }
            }
        }
    }
    .footerSection{
        margin: 170px 0px 0px 0px;
        width: 100%;
        height: 120px;
        .footerContainer{
            height: 90px;
            border-radius: 20px;
            margin-top: -75px;
            a{
                font-size: 14px;
                line-height: 21px;
            }
            img{
                width: 25px;
                height: 25px;
            }
        }
        .copyrightContainer{
            padding: 50px 0px 0px 0px;
            .text{
                font-size: 12px;
                line-height: 21px;
            }
            .iconsContainer{
                gap: 15px;
                div {
                    width: 30px;
                    height: 30px;
                    svg{
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
}

@media (min-width: 768px) and (max-width:1024px) {
    .first-section{
        width: 95%;
        background-position: right top 150px ;
        background-size:650px;
    }
    .sectionContainer{
        width: 95%;
    }
    .first-section{
        .container{
            p{
                font-size: 48px;
                line-height: 70px;
            }
            .button-container{
                div{
                    width: 130px;
                    height: 51px;
                    gap: 10px;
                    a{
                        line-height: 18px;
                        font-size: 7.5px;
                    }
                }
            }
            .read-more{
                div{
                    width: 150px;
                    height: 73px;
                    a{
                        font-size: 14px;
                    }
                }
            }
        }
    }
    .first-section .container .button-container{
        gap: 7px !important;
        padding: 7px 0px;
    }
    .section-title{
        font-size: 38px !important;
        line-height: 55px !important;
    }
    .cardsContainer{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
        gap:70px;
        justify-content: center;
        justify-items: center;
        .cardContianer{
            width: 90%;
            max-width: 400px;
            height: auto !important;
            padding: 0px;
            margin: 0px;
            .imageContainer{
                height: 100px;
            }
        }
    }
    .greenCard{
        .imageContainer{
            height: 125px;
            margin-bottom: -25px;
        }
    }
    .pinkCard{
        .contentContainer{
            padding: 110px 40px 40px 40px!important;
        }
    }
    .contentContainer{
        padding: 0px 40px 40px 40px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
        .title{
            font-size: 30px !important;
        }
        .description{
            font-size: 14px !important;
        }
    }
    .imageContainerBottom{
        display: flex;
        justify-content: center;
        .bottom{
            position: absolute;
            top: -60px;
        }
    }
    .pinkLine{
        top:auto !important;
        bottom: -8px;
    }
    .thirdSection{
        .section-title{
            margin: 20px;
        }
        .cardsContainer{
            gap: 10px;
            padding: 55px 0px 5px 0px;
            .leftCardContainer{
                border-radius: 30px;
                height: auto;
                .contentsContainer{
                    padding: 0px 40px;
                    .mintingTitle{
                        font-size: 26px;
                        line-height: 53px;
                    }
                    .mintingDescription{
                        font-size: 12px;
                        line-height: 23px;
                    }
                }
            }
            .rightCardContainer{
                height: auto;
                grid-template-rows:none;
                .topCardContainer{
                    height: auto;
                    .leftCard{
                        border-radius: 30px;
                        height: auto;
                        .img{
                            width: 100%;
                            height: 100%;
                            border-radius: 30px;
                        }
                    }
                    .rightCard{
                        border-radius: 30px;
                        height: auto;
                        .img{
                            width: 100%;
                            height: 100%;
                            border-radius: 30px;
                        }
                    }
                }
                .bottomCardContainer{
                    height: auto;
                    border-radius: 30px;
                    .img{
                        border-radius: 30px;
                        height: 100%
                    }
                }
            }
        }
        .miningImage{
            position: absolute;
            top: -80px;
            right: -80px;
            width: 400px;
            height: 400px;
        }
        .topContainer{
            height: 200px;
        }
        .vectorsContainer{
            .yellowContainer{
                top: 4%;
                left: 20%;
                .victor{
                    right: -10px;
                    bottom: -20px;
                }
            }
            .blueContainer{
                top: 13%;
                left: 12%;
                .victor{
                    right: -25px;
                    bottom: 0px;
                }
            }
            .pinkContainer{
                top: 22%;
                left: 15%;
                .victor{
                    right: -25px;
                    bottom: 0px;
                }
            }
            .orangeContainer{
                top: 30%;
                left: 20%;
                .victor{
                    right: -25px;
                    bottom: 10px;
                }
            }

        }
    }
    .arrowsContainer{
        padding: 0px 40px !important;
        .leftArrow{
            width: 45px !important;
            height: 45px !important;
            background-image: url('../assets/images/left-arrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            cursor: pointer;
            display: inline-block;
        }
        .rightArrow{
            width: 45px !important;
            height: 45px !important;
            margin-left: 15px !important;
            background-image: url('../assets/images/right-arrow.svg');
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            cursor: pointer;
            display: inline-block;
        }
    }
    .recivingCardContainer{
        .cardTitle{
            padding-left: 40px !important;
            margin: -10px 0px !important;
        }
        .cardDescription{
            padding-left: 40px !important;
        }
    }
    .mintingCardContainer{
        .cardTitle{
            padding-left: 50px !important;
            margin: -10px 0px !important;
        }
        .cardDescription{
            padding-left: 50px !important;
            margin: -10px 0px !important;
        }
    }
    .burningCardContainer{
        .cardTitle{
            padding-left: 40px !important;
        }
        .cardDescription{
            padding-left: 40px !important;
        }
    }
    .stakingCardContainer{
        .cardTitle{
            margin: -42px 0px;
            padding-left: 50px !important;
        }
        .cardDescription{
            padding-left: 50px !important;
            margin: -45px 0px !important;
        }
    }
    .title{
        margin: 0px;
    }
    .summaryContainer{
        padding: 20px 30px !important;
        .title{
            font-size: 26px !important;
        }
    }
    .summaryCardsContainer{
        gap:5px !important;
        .cardContainer{
            height: 220px !important;
            .cardTitle{
                font-size: 20px !important;
            }
            .cardDescription{
                font-size: 12px !important;
                line-height: 20px !important;
                max-width: 250px !important;
            }
        }
    }
    .qaSection{
        .qasContainer{
            padding: 50px 20px;
            width: 100%;
            box-sizing: border-box;
            .openArrow{
                width: 15px;
                height: 28px;
            }
            .closeArrow{
                width: 15px;
                height: 28px;
            }
        }
        .qaContainer{
            width: 90%;
            border-radius: 15px;
            .questionContainer{
                padding: 15px 0px;
            }
            .question{
                font-size: 16px;
                line-height: 35px;
            }
            .answar{
                font-weight: 600;
                font-size: 12px;
                line-height: 20px;
            }
        }
    }
    .fourthSection{
        padding: 60px 0px;
        .wlitecoin-cover{
            height: 500px;
            width: 100%;
            background-size: contain;

            .wlitecoin-logo{
                height: 100%;
                img{
                    width: 200px;
                    height: 200px;
                }
            }
        }
    }
    .joinCommunity{
        .buttonsContainer{
            padding: 30px 0px;
            gap: 30px;
        }
        .button{
            div{
                background-size: contain;
                width: 150px;
                height: 55px;
                a{
                    font-size: 11px;
                    line-height: 25px;
                    padding: 16px 0px 0px 0px;
                }
            }
        }
    }
    .partnersContainer{
        padding: 180px 0px 0px 0px;
        .cardsContainer{
            padding: 70px 0px;
            .cardContainer{
                width: 170px;
                height: 180px;
                border-radius: 40px;
                .card{
                    width: 170px;
                    height: 130px;
                    border-radius: 40px;
                    p{
                        padding: 0px;
                        margin: 0px ;
                        font-weight: 850;
                        font-size: 20px;
                    }
                    .img{
                        padding: 20px 0px 0px 0px;
                        width: 40px;
                    }
                }
            }
        }
    }
    .footerSection{
        margin: 200px 0px 0px 0px;
        width: 100%;
        height: 200px;
        .footerContainer{
            height: 125px;
            border-radius: 25px;
            margin-top: -75px;
            a{
                font-size: 16px;
                line-height: 21px;
            }
            img{
                width: 30px;
                height: 30px;
            }
        }
        .copyrightContainer{
            padding: 60px 0px 0px 0px;
            .text{
                font-size: 14px;
                line-height: 21px;
            }
            .iconsContainer{
                gap: 20px;
                div {
                    width: 35px;
                    height: 35px;
                    svg{
                        cursor: pointer;
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1025px) and (max-width:1200px) {
    .first-section{
        width: 85%;
        background-position: right top 150px ;
        background-size:750px;
    }
    .sectionContainer{
        width: 85%;
    }
    .first-section{
        .container{
            p{
                font-size: 54px;
                line-height: 80px;
            }
            .button-container{
                div{
                    width: 150px;
                    height: 51px;
                    gap: 10px;
                    a{

                        font-size: 9px;
                        line-height: 18px;
                    }
                }
            }
            .read-more{
                div{
                    width: 165px;
                    height: 73px;
                    a{
                        font-size: 15px;
                    }
                }
            }
        }
    }
    .first-section .container .button-container{
        gap: 10px !important;
        padding: 10px 0px;
    }
    .section-title{
        font-size: 48px !important;
        line-height: 60px !important;
    }
    .pinkCard{
        .contentContainer{
            padding: 110px 40px 40px 40px!important;
        }
    }
    .contentContainer{
        padding: 0px 40px 40px 40px !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
        .title{
            font-size: 30px !important;
        }
        .description{
            font-size: 13px !important;
        }
    }
    .cardsContainer{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: auto;
        gap:70px;
        justify-content: center;
        justify-items: center;
        .cardContianer{
            max-width: 400px;
            width: 90%;
            height: auto !important;
            padding: 0px;
            margin: 0px;
            .imageContainer{
                height: 100px;
            }
        }
        .greenCard{
            .imageContainer{
                height: 125px;
                margin-bottom: -25px;
            }
        }
    }
    .contentContainer{
        padding-left: 30px !important;
        padding-right: 30px !important;
        .title{
            font-size: 34px !important;
        }
        .description{
            font-size: 16px !important;
        }
    }
    .imageContainerBottom{
        display: flex;
        justify-content: center;
        .bottom{
            position: absolute;
            top: -60px;
        }
    }
    .pinkLine{
        top:auto !important;
        bottom: -8px;

    }
    .thirdSection{
        .section-title{
            margin: 20px;
        }
        .cardsContainer{
            gap:5px;
            padding: 55px 0px 5px 0px;
            .leftCardContainer{
                border-radius: 35px;
                height: auto;
                .contentsContainer{
                    padding: 0px 70px;
                    .mintingTitle{
                        font-size: 30px;
                        line-height: 53px;
                    }
                    .mintingDescription{
                        font-size: 13px;
                        line-height: 18px;
                    }
                }
            }
            .rightCardContainer{
                height: auto;
                grid-template-rows:none;
                .topCardContainer{
                    height: auto;
                    .leftCard{
                        height: auto;
                        border-radius: 35px;
                        .img{
                            border-radius: 35px;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .rightCard{
                        border-radius: 35px;
                        height: auto;
                        .img{
                            border-radius: 35px;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .bottomCardContainer{
                    height: auto;
                    border-radius: 35px;
                    .img{
                        border-radius: 35px;
                        height: 100%
                    }
                }
            }
        }
        .miningImage{
            position: absolute;
            top: -80px;
            right: -80px;
            width: 400px;
            height: 400px;
        }
        .topContainer{
            height: 200px;
        }
        .vectorsContainer{
            .yellowContainer{
                top: 4%;
                left: 20%;
                .victor{
                    right: -10px;
                    bottom: -20px;
                }
            }
            .blueContainer{
                top: 13%;
                left: 12%;
                .victor{
                    right: -25px;
                    bottom: 0px;
                }
            }
            .pinkContainer{
                top: 22%;
                left: 15%;
                .victor{
                    right: -25px;
                    bottom: 0px;
                }
            }
            .orangeContainer{
                top: 30%;
                left: 20%;
                .victor{
                    right: -25px;
                    bottom: 10px;
                }
            }

        }
    }
    .recivingCardContainer{
        .cardTitle{
            padding-left: 40px !important;
            margin: -10px 0px !important;
        }
        .cardDescription{
            padding-left: 40px !important;
        }
    }
    .mintingCardContainer{
        .cardTitle{
            padding-left: 50px !important;
            margin: -10px 0px !important;
        }
        .cardDescription{
            padding-left: 50px !important;
            margin: -10px 0px !important;
        }
    }
    .burningCardContainer{
        .cardTitle{
            padding-left: 40px !important;
        }
        .cardDescription{
            padding-left: 40px !important;
        }
    }
    .stakingCardContainer{
        .cardTitle{
            margin: -42px 0px;
            padding-left: 50px !important;
        }
        .cardDescription{
            padding-left: 50px !important;
            margin: -45px 0px !important;
        }
    }
    .title{
        margin: 0px;
    }
    .summaryContainer{
        padding: 30px 70px !important;
    }
    .summaryCardsContainer{
        gap:15px !important;
        .cardContainer{
            height: 250px !important;
            .cardTitle{
                font-size: 24px !important;
            }
            .cardDescription{
                font-size: 13px !important;
                line-height: 18px !important;
                max-width: 300px !important;
            }
        }
    }
    .qaSection{
        .qasContainer{
            width: 100%;
            padding: 50px 20px;
            box-sizing: border-box;
            .openArrow{
                width: 15px;
                height: 28px;
            }
            .closeArrow{
                width: 15px;
                height: 28px;
            }
        }
        .qaContainer{
            width: 90%;
            border-radius: 15px;
            .questionContainer{
                padding: 20px 0px;
            }
            .question{
                font-size: 17px;
                line-height: 35px;
            }
            .answar{
                font-weight: 600;
                font-size: 13px;
                line-height: 20px;
            }
        }
    }
    .fourthSection{
        padding: 60px 0px;
        .wlitecoin-cover{
            height: 550px;
            width: 100%;
            background-size: contain;

            .wlitecoin-logo{
                height: 100%;
                img{
                    width: 250px;
                    height: 250px;
                }
            }
        }
    }
    .joinCommunity{
        .buttonsContainer{
            padding: 40px 0px;
            gap: 35px;
        }
        .button{
            div{
                background-size: contain;
                width: 180px;
                height: 65px;
                a{
                    font-size: 13px;
                    line-height: 25px;
                    padding: 20px 0px 0px 0px;
                }
            }
        }
    }
    .partnersCover{
        img{
            padding: 100px 0px 0px 0px;
            position: absolute;
            width: 100%;
            max-height: 1000px;
        }
    }
    .partnersContainer{
        padding: 200px 0px 0px 0px;
        .cardsContainer{
            padding: 70px 0px;
            .cardContainer{
                width: 220px;
                height: 230px;
                border-radius: 60px;
                .card{
                    width: 220px;
                    height: 180px;
                    border-radius: 60px;
                    p{
                        padding: 0px;
                        margin: px 0px ;
                        font-weight: 850;
                        font-size: 26px;
                    }
                    .img{
                        padding: 30px 0px 0px 0px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1201px) and (max-width: 1680px) {
    .first-section{
        width: 80%;
        background-position: right top 150px ;
        background-size:900px;
    }
    .sectionContainer{
        width: 80%;
    }
    .first-section{
        .container{
            p{
                font-size: 60px;
            }
            .button-container{
                padding: 25px 0px;
                div{
                    width: 200px;
                    height: 65px;
                    gap: 0px;
                    a{

                        font-size: 12px;
                        line-height: 30px;
                    }
                }
            }
            .read-more{
                div{
                    width: 175px;
                    height: 70px;
                    a{
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .first-section .container .button-container{
        gap: 10px !important;
    }
    .section-title{
        font-size: 54px !important;
    }
    .contentContainer{
        padding-left: 5px !important;
        padding-right: 5px !important;
        .title{
            font-size: 34px !important;
        }
        .description{
            font-size: 14px !important;
        }
    }
    .thirdSection{
        .section-title{
            margin: 20px;
        }
        .cardsContainer{
            gap: 10px;
            padding: 55px 0px 5px 0px;
            .leftCardContainer{
                border-radius: 35px;
                height: auto;
                .contentsContainer{
                    padding: 0px 70px;
                    .mintingTitle{
                        font-size: 30px;
                        line-height: 53px;
                    }
                    .mintingDescription{
                        font-size: 15px;
                        line-height: 22px;
                    }
                }
            }
            .rightCardContainer{
                height: auto;
                grid-template-rows:none;
                .topCardContainer{
                    height: auto;
                    .leftCard{
                        height: auto;
                        border-radius: 35px;
                        .img{
                            border-radius: 35px;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .rightCard{
                        border-radius: 35px;
                        height: auto;
                        .img{
                            border-radius: 35px;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .bottomCardContainer{
                    height: auto;
                    border-radius: 35px;
                    .img{
                        border-radius: 35px;
                        height: 100%
                    }
                }
            }
        }
        .miningImage{
            position: absolute;
            top: -80px;
            right: -80px;
            width: 500px;
            height: 500px;
        }
        .topContainer{
            height: 300px;
        }
        .vectorsContainer{
            .yellowContainer{
                top: 4%;
                left: 20%;
                .victor{
                    right: -10px;
                    bottom: -20px;
                }
            }
            .blueContainer{
                top: 13%;
                left: 12%;
                .victor{
                    right: -25px;
                    bottom: 0px;
                }
            }
            .pinkContainer{
                top: 22%;
                left: 15%;
                .victor{
                    right: -25px;
                    bottom: 0px;
                }
            }
            .orangeContainer{
                top: 30%;
                left: 20%;
                .victor{
                    right: -25px;
                    bottom: 10px;
                }
            }

        }
    }
    .recivingCardContainer{
        .cardTitle{
            padding-left: 40px !important;
            margin: -10px 0px !important;
        }
        .cardDescription{
            padding-left: 40px !important;
        }
    }
    .mintingCardContainer{
        .cardTitle{
            padding-left: 50px !important;
            margin: -10px 0px !important;
        }
        .cardDescription{
            padding-left: 50px !important;
            margin: -10px 0px !important;
        }
    }
    .burningCardContainer{
        .cardTitle{
            padding-left: 40px !important;
        }
        .cardDescription{
            padding-left: 40px !important;
        }
    }
    .stakingCardContainer{
        .cardTitle{
            margin: -42px 0px;
            padding-left: 50px !important;
        }
        .cardDescription{
            padding-left: 50px !important;
            margin: -45px 0px !important;
        }
    }
    .summaryContainer{
        padding: 30px 70px !important;
    }
    .title{
        margin: 5px 0px;
    }
    .summaryCardsContainer{
        gap:5px !important;
        .cardContainer{
            height: 250px !important;
            .cardTitle{
                font-size: 24px !important;
            }
            .cardDescription{
                font-size: 16px !important;
                line-height: 20px !important;
                max-width: 320px !important;
            }
        }
    }
    .qaSection{
        padding: 50px 0px;
        .qasContainer{
            padding: 20px 0px;
            margin: 20px 0px;
            width: 100%;
            .openArrow{
                width: 15px;
                height: 28px;
            }
            .closeArrow{
                width: 15px;
                height: 28px;
            }
        }
        .qaContainer{
            margin-top: 5px;
            width: 90%;
            .question{
                font-size: 24px;
                line-height: 40px;
            }
            .answar{
                font-weight: 600;
                font-size: 20px;
                line-height: 38px;
            }
        }
    }
    .second-section{
        .section-title{
            line-height: 60px !important;
        }
        .cardsContainer .cardContianer{
            height: 500px !important;
        }
    }
}

@media (min-width: 1681px) and (max-width: 2000px) {
    .first-section{
        width: 75%;
        background-position: right top 115px ;
        background-size:1000px;
        height: 700px;
        .button-container{
            padding: 20px 0px !important;
            gap: 10px !important;
            div{
                width: 250px !important;
                height: 65px !important;
                a{
                    font-size: 15px !important;
                    line-height: 30px !important;
                }
            }
        }
    }

    .sectionContainer{
        width: 75%;
    }

    .stakingCardContainer{
        .cardTitle{
            padding-left: 60px !important;
        }
    }
    .mintingCardContainer{
        .cardTitle{
            padding-left: 60px !important;
        }
    }

    .thirdSection{
        .section-title{
            margin: 20px;
        }
        .cardsContainer{
            gap: 10px;
            padding: 55px 0px 5px 0px;
            .leftCardContainer{
                border-radius: 35px;
                height: auto;
                .contentsContainer{
                    padding: 0px 70px;
                    .mintingTitle{
                        font-size: 30px;
                        line-height: 53px;
                    }
                    .mintingDescription{
                        font-size: 18px;
                        line-height: 22px;
                    }
                }
            }
            .rightCardContainer{
                height: auto;
                grid-template-rows:none;
                .topCardContainer{
                    height: auto;
                    .leftCard{
                        height: auto;
                        border-radius: 35px;
                        .img{
                            border-radius: 35px;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .rightCard{
                        border-radius: 35px;
                        height: auto;
                        .img{
                            border-radius: 35px;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .bottomCardContainer{
                    height: auto;
                    border-radius: 35px;
                    .img{
                        border-radius: 35px;
                        height: 100%
                    }
                }
            }
        }
        .miningImage{
            position: absolute;
            top: -80px;
            right: -80px;
            width: 600px;
            height: 600px;
        }
        .topContainer{
            height: 400px;
        }
        .vectorsContainer{
            .yellowContainer{
                top: 4%;
                left: 20%;
                .victor{
                    right: -10px;
                    bottom: -20px;
                }
            }
            .blueContainer{
                top: 13%;
                left: 12%;
                .victor{
                    right: -25px;
                    bottom: 0px;
                }
            }
            .pinkContainer{
                top: 22%;
                left: 15%;
                .victor{
                    right: -25px;
                    bottom: 0px;
                }
            }
            .orangeContainer{
                top: 30%;
                left: 20%;
                .victor{
                    right: -25px;
                    bottom: 10px;
                }
            }

        }
    }
}

@media (min-width: 2001px) {
    .first-section{
        width: 70%;
        background-position: right top -10px ;
        background-size:1300px;
        height: 700px;
        .button-container{
            padding: 20px 0px !important;
            gap: 10px !important;
            div{
                width: 250px !important;
                height: 65px !important;
                a{
                    font-size: 15px !important;
                    line-height: 30px !important;
                }
            }
        }
    }

    .sectionContainer{
        width: 70%;
    }

    .thirdSection{
        .cardsContainer{
            .leftCardContainer{
                height: auto;
                .contentsContainer{
                    .mintingTitle{
                        font-size: 40px;
                        line-height: 53px;
                    }
                    .mintingDescription{
                        font-size: 28px;
                        line-height: 32px;
                    }
                }
            }
            .rightCardContainer{
                height: auto;
                grid-template-rows:none;
                .topCardContainer{
                    height: auto;
                    .leftCard{
                        height: auto;
                        border-radius: 35px;
                        .img{
                            border-radius: 35px;
                            width: 100%;
                            height: 100%;
                        }
                    }
                    .rightCard{
                        border-radius: 35px;
                        height: auto;
                        .img{
                            border-radius: 35px;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
                .bottomCardContainer{
                    height: auto;
                    border-radius: 35px;
                    .img{
                        border-radius: 35px;
                        height: 100%
                    }
                }
            }
        }
        .vectorsContainer{
            .vectorContainer{
                width: 130px;
                height: 30px;
                p{
                    font-size: 22px;
                }
            }
        }
    }
}
