html, body {
  height: 100%;
}

.ComingSoon {
  background-image: url('/src/assets/images/background.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-direction: column;
}

.logo {
  text-align: center;
}

.title {
  color: #fff;
  font-family: Futura;
  font-weight: 700;
  font-size: 34px;
  line-height: 44px;
}
